import toastr from 'toastr';

import '../../node_modules/toastr/build/toastr.css';

import Tools from './Tools';

import translate from './translate';

toastr.options = {
    "closeButton": false,
    "debug": false,
    "newestOnTop": false,
    "progressBar": false,
    "positionClass": "toast-top-right",
    "preventDuplicates": false,
    "onclick": null,
    "showDuration": "300",
    "hideDuration": "1000",
    "timeOut": "5000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
};

toastr.warn = (obj, errorKey) => {

    let defaultMessage = translate(errorKey || 'errors.generic');

    let responseErrorMessage = Tools.getJSON(obj, 'status.error_message');
    if(responseErrorMessage) {
        defaultMessage = translate('errors.' + responseErrorMessage.toLowerCase(), { _: defaultMessage });
    }
    toastr.warning(defaultMessage);
};

export default toastr;
