import request from 'superagent';

import Storage from './Storage';

import Config from './Config';

let apiURL = null;

// The list of public path where it is not required to be authenticated
// It is a corresponding list with the patterns (without ^/api/ ans security set to false) that you 
// can find in the section firewalls in config/packages/security.yaml on server side
const public_paths = [
    'login',
    'front/auth',
    'front/media',
    'front/institution',
    'front/password',
    'front/preview'
]

function logoutAndRedirectToLogin(){
    let pathPrev = window.location.href.includes('/preview')
    if (!pathPrev) {
        Storage.logout()
        window.location.href = '/login'
    }
}

class Ajax {

    static getAPIUrl(pathName) {
        if (!apiURL) {
            let host_without_www = window.location.hostname.replace('www.', '');

            apiURL = Config.get('API_URL', '').replace('{host_without_www}', host_without_www);

            if (apiURL.lastIndexOf('/') !== apiURL.length - 1) {
                apiURL += '/';
            }
        }
        return apiURL + pathName;
    }

    static getArguments(params = {}, headers = {}, callback) {
        if (typeof params === "function") {
            callback = params;
            params = {};
            headers = {};
        }
        else if (typeof headers === 'function') {
            callback = headers;
            headers = {};
        }
        return { params, headers, callback };
    }

    static requestApi(type, path, p, h, c) {

        let { params, headers, callback } = Ajax.getArguments(p, h, c);
        let newUrl = Ajax.getAPIUrl(path);

        // Before sending we must verify if the token has not expired
        // if so we must do a POST request to /api/auth/access_token with body { "refresh_token": "OUR_REFRESH_TOKEN" }
        // if we have no refresh token or a 400 response we redirect the user to the login page
        
        if(!public_paths.some((publicPath) => path.startsWith(publicPath)) && Storage.tokenExpiresOn < (Date.now() / 1000)) {
            
            const refresh_token = Storage.refreshToken

            if (refresh_token !== null) {
                console.warn('send request refresh token')
                request.post(Ajax.getAPIUrl('front/auth/access_token'))
                    .set('Accept', 'application/json')
                    .send({ refresh_token: refresh_token })
                    .end((err, res) => {
                        if(err){
                            logoutAndRedirectToLogin()
                        }else{
                            console.warn('success request refresh token')
                            const new_auth_token = res.body.body.token
                            console.warn('new access token:', new_auth_token)
                            Storage.authToken = new_auth_token
                            headers['Authorization'] = 'Bearer ' + new_auth_token;

                            return Ajax.request(type, newUrl, params, headers, callback);
                        }
                    })

            } else {
                logoutAndRedirectToLogin()
            }
        } else {
            if (Storage.authToken) {
                headers['Authorization'] = 'Bearer ' + Storage.authToken;
            }

            return Ajax.request(type, newUrl, params, headers, callback);
        }
    }

    static request(type, url, p, h, c) {

        let { params, headers, callback } = Ajax.getArguments(p, h, c);

        let r = request[type](url)
            .set('Accept', 'application/json');

        let keys = Object.keys(headers);

        // set custom headers
        if (keys.length) {
            for (let key of keys) {
                r.set(key, headers[key]);
            }
        }

        if (type === 'get') {
            r.query(params);
        }
        else {
            r.send(params);
        }

        return r.end((err, res) => {
            if (err) {
                console.log(err);
            }
            if (typeof callback === 'function') {
                return callback(err, res ? res.body : null, res);
            }
        });
    }

    // API Calls
    static get(url, params, headers, callback) {
        return Ajax.requestApi('get', url, params, headers, callback);
    }

    static post(url, params, headers, callback) {
        return Ajax.requestApi('post', url, params, headers, callback);
    }

    static put(url, params, headers, callback) {
        return Ajax.requestApi('put', url, params, headers, callback);
    }

    // NOTE(nicolas joly):  
    // This 3 functions seems not used, if so why and where are they used 
    // Must be @REMOVE

    // Other calls
    static getRaw(url, params, headers, callback) {
        return Ajax.request('get', url, params, headers, callback);
    }

    static postRaw(url, params, headers, callback) {
        return Ajax.request('post', url, params, headers, callback);
    }

    static putRaw(url, params, headers, callback) {
        return Ajax.request('put', url, params, headers, callback);
    }
}

export default Ajax;