import Tools from "./Tools";

let defaultOptions = {
    url     : {
        origin: '/SAML2',
        params: {
            redirectSubDomain: 'www'
        }
    }, // mandatory
    method  : 'GET', // automatic, GET if no params, POST otherwise
    type    : 'redirect', // type of SSO => redirect (default) or popup
};

class SSO {

    TAG = 'SSO';

    POPUP_TARGET_NAME = 'view';

    static TYPE_POPUP = 'popup';

    static TYPE_REDIRECT = 'redirect';

    constructor(options = {}) {

        this.options = Tools.deepExtend(defaultOptions, options);

        if(!this.redirect) {
            Tools.debug(this.TAG, 'SSO opening window');

            this.popup = window.open('', this.POPUP_TARGET_NAME);

            this.popup.document.write('<div>Connecting ...<div>');

            if(!this.popup) {
                throw Error('SSO popup cannot be open !');
            }
        }
    }

    get url() {

        if(this.redirect) {
            //this.options.url.params.forceMethod = SSO.TYPE_REDIRECT;
        }

        return this._constructUrl(this.options.url.origin, this.options.url.params);
    }

    get redirect() {
        return this.options.type === SSO.TYPE_REDIRECT;
    }

    login(params = {}, onClose) {

        if(this.isOpen) {
            return;
        }

        if(typeof params === 'function') {
            onClose = params;
            params = {};
        }

        this.isOpen = true;

        this.params = params;

        if(!this.url) {
            throw Error('No URL defined');
        }

        // get the method, automatic or forced

        let method = this.options.method;

        if(!method) {
            let names = Object.keys(this.params);
            if(names.length) {
                method = 'POST';
            }
        }

        Tools.debug(this.TAG, [
            'SSO method ==> ' + method,
            'SSO params ==> ' + JSON.stringify(this.params)
        ]);

        // get the popup

        if(method === 'POST') {
            this._post();
        }
        else {
            this._get();
        }

        if(this.popup) {
            let interval = setInterval(() => {
                if(this.popup.closed) {

                    Tools.debug(this.TAG, 'SSO onClose');

                    clearInterval(interval);

                    if(this.form) {
                        document.body.removeChild(this.form);
                    }
                    if(typeof onClose === 'function') {
                        onClose();
                    }

                    this.isOpen = false;
                }
            }, 100);
        }

    }

    _get() {

        let url = this._constructUrl(this.url, this.params);

        Tools.debug(this.TAG, [
            'SSO url ==> ' + url,
            'SSO encoded url ==> ' + encodeURI(url),
            'SSO opening window'
        ]);

        if(this.redirect) {
            window.location.href = encodeURI(url);
        }
        else if(this.popup) {
            this.popup.location.href = encodeURI(url);
        }
    }

    _constructUrl(url, params) {

        let names = Object.keys(params);

        if(names.length) {

            Tools.debug(this.TAG, 'SSO adding params to url');

            if(url.indexOf('?') === -1) {
                url += '?';
            }
            else {
                url += '&';
            }

            for(let name of names) {
                url += name + '=' + params[name] + '&';
            }

            Tools.debug(this.TAG, 'SSO url before slice ==> ' + url);

            // remove the last '&' character
            url = url.slice(0, -1);
        }
        return url;
    }

    _post() {

        this.form = document.createElement('form');
        this.form.setAttribute('method', 'post');
        this.form.setAttribute('action', this.url);

        if(this.popup) {
            this.form.setAttribute('target', this.POPUP_TARGET_NAME);
        }

        let names = Object.keys(this.params);

        if(names && names.length) {

            Tools.debug(this.TAG, 'SSO adding params to form');

            for(let name of names) {
                this.addInput(name);
            }
        }

        document.body.appendChild(this.form);

        Tools.debug(this.TAG, 'SSO submitting form');

        this.form.submit();

    }

    addInput(name) {

        let hiddenField = document.createElement('input');

        hiddenField.setAttribute('type', 'hidden');
        hiddenField.setAttribute('name', name);
        hiddenField.setAttribute('value', this.params[name]);

        this.form.appendChild(hiddenField);
    }

    close() {

        if(this.popup) {
            Tools.debug(this.TAG, 'SSO Closing window');

            this.popup.close();

            this.isOpen = false;
        }
    }

}

export default SSO;