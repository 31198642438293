/*
  Return theming value for a given key
 */
import { createTheme } from '@material-ui/core';
import { Ajax, Config, Storage, Tools } from './index';

class Theming {

    static colorCache = {};

    static DEFAULT_LANGUAGE = 'en';

    static get(key, defaultValue) {

        // try to get the theming key for a given challengeId

        let theme;

        if(Tools.isObject(Storage.previewTheme)) {
            theme = Storage.previewTheme;
        }
        else {
            theme = Storage.getCampaign('theme') || Storage.getInstitution('theme') || Config.get('THEME');
            /*theme = Tools.deepExtend(
                {},
                Config.get('THEME'),
                Storage.getInstitution('theme'),
                Storage.getCampaign('theme')
            );*/
        }

        return Tools.getJSON(theme, key, defaultValue);
    }

    static translations(key) {
        const translations = Storage.getCampaign('translations') || Storage.getInstitution('translations');

        if(key) {
            return translations[key];
        }

        return translations;
    }

    static getBrowserLanguage() {
        if(navigator.language) {
            return navigator.language.split('-')[0];
        }
        return null;
    }

    static language() {
        let languages = [
            () => Storage.getProfile('language'),
            () => Storage.getCampaign('language'),
            () => this.getBrowserLanguage(),
            () => Storage.getInstitution('language'),
        ];

        for(let language of languages) {
            let thisLanguage = language();

            if(this.isLanguageAvailable(thisLanguage)) {
                return thisLanguage;
            }
        }

        return this.DEFAULT_LANGUAGE;
    }

    static availableLanguages() {
        try {
            let selectedlanguage = Storage.getCampaign('availableLanguages') || Storage.getInstitution('availableLanguages');
            return selectedlanguage
        } catch (error) {
            return this.DEFAULT_LANGUAGE;
        }
    }

    static isLanguageAvailable(language) {
        let avalaibleLanguage = this.availableLanguages()?this.availableLanguages():[];
        return language && avalaibleLanguage.indexOf(language) !== -1;
    }

    static url(key, defaultValue) {
        return Theming.get(key + 'Url', defaultValue);
    }

    static imagePath(srn) {
        return Ajax.getAPIUrl('front/media/' + srn);
    }

    static image(key, defaultValue) {
        return Theming.get(key + 'Image', defaultValue);
    }

    static agreement(key, defaultValue) {
        return Theming.get(key + 'Agreement', defaultValue);
    }

    static icon(key, defaultValue) {
        return Theming.get(key + 'Icon', defaultValue || Theming.getDefaultIcon(key));
    }

    static getDefaultIcon(type) {
        return type === 'knowledge' ? 'vpn_key' : 'star';
    }

    static _color(type, colorName, opacity, forText) {
        if(typeof colorName === 'number') {
            opacity = colorName;
            colorName = null;
        }
        else if(!opacity) {
            opacity = 1;
        }

        let hex;

        if(colorName) {
            hex = Theming.get('colors.' + colorName);
        }

        if(!hex) {
            hex = Theming.get(type + (forText ? 'Text' : '') + 'Color');
        }

        if(hex) {
            let rgb = Theming.colorCache[hex];

            if(rgb === undefined) {
                rgb = Tools.hexToRgb(hex);
                Theming.colorCache[hex] = rgb;
            }

            if(rgb) {
                return 'rgba(' + rgb.r + ',' + rgb.g + ',' + rgb.b + ',' + opacity + ')';
            }

        }

        return null;
    }

    /**
     *
     * @param type type of color (primary, secondary, tertiary)
     * @param colorName a name of attribute color
     * @param opacity
     * @returns string
     */
    static color(type, colorName, opacity) {
        return Theming._color(type, colorName, opacity);
    }

    static textColor(type, colorName, opacity) {
        return Theming._color(type, colorName, opacity, true);
    }

    static updateTheme() {
       
        document.documentElement.style.setProperty("--primary", Theming.color('primary'));
        document.documentElement.style.setProperty("--textprimary", Theming.textColor('primary'));
        document.documentElement.style.setProperty("--secondary", Theming.color('secondary'));
        document.documentElement.style.setProperty("--textsecondary", Theming.textColor('secondary'));
        document.documentElement.style.setProperty("--tertiary", Theming.color('tertiary'));
        document.documentElement.style.setProperty("--texttertiary", Theming.textColor('tertiary'));
        document.documentElement.style.setProperty("--cta", Theming.color('cta'));
        document.documentElement.style.setProperty("--textcta", Theming.textColor('cta'));

        return  createTheme({
            palette: {
                primary: {
                    main: Theming.color('primary'),
                    contrastText: Theming.textColor('primary')
                },
                secondary: {
                    main: Theming.color('secondary'),
                    contrastText: Theming.textColor('secondary')
                },
                tertiary: {
                    main: Theming.color('tertiary'),
                    contrastText: Theming.textColor('tertiary')
                },
                cta: {
                    main: Theming.color('cta'),
                    contrastText: Theming.textColor('cta')
                }
            }
        });
    }
}

export default Theming;
