const animationTimeout = 10;

class Elements {

    static getSize(element, type = 'width') {
        let rect = element.getBoundingClientRect();
        if (rect[type]) {
            // size are available for IE9+
            return rect[type];
        }
        // Calculate width for IE8 and below
        if(type === 'height') {
            return rect.bottom - rect.top;
        }
        return rect.right - rect.left;
    }

    static _animate(obj, type, from, to, decr, step, unity, callback) {

        setTimeout(() => {

            if(decr) {
                from -= step;
                if(from < to) {
                    from = to;
                }
            }
            else {
                from += step;
                if(from > to) {
                    from = to;
                }
            }

            obj[type] = from + unity;

            let isFinished = from === to;

            if(isFinished) {
                if(callback) {
                    callback();
                }
            }
            else {
                Elements._animate(obj, type, from, to, decr, step, unity, callback);
            }

        }, animationTimeout);
    }

    /**
     *
     * @param element element to animate
     * @param type element attr to animate (opacity, width...)
     * @param to value of the attr to reach
     * @param duration duration of the animation in ms
     * @param callback function to call when the animation is over
     * @param unity unity of the attr
     */
    static animate(element, type, to, duration, callback, unity = 'px') {

        let obj = element;

        if(type !== 'scrollTop') {
            obj = element.style;
        }
        else {
            unity = '';
        }

        if(!obj) {
            return;
        }

        let from = obj[type] || 0;

        if(typeof from === 'string') {
            from = parseFloat(from.replace('px|%', ''));
        }

        let diff = from - to;
        let decr = diff > 0;
        let step = Math.abs(diff) * animationTimeout / duration;

        Elements._animate(obj, type, from, to, decr, step, unity, callback);

    }

    static fadeOut(element, duration, callback) {

        let step = animationTimeout / duration;

        Elements._animate(element.style, 'opacity', 1, 0, true, step, '', callback);
    }

    static fadeIn(element, duration, callback) {

        let step = animationTimeout / duration;

        Elements._animate(element.style, 'opacity', 0, 1, false, step, '', callback);
    }

};

export default Elements;