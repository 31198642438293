/*
 Return config value for a given key
 */

import config from '../config.json';

import Tools from './Tools';

const host = window.location.host;

let _configs = {};

class Config {

    static get(key, defaultValue) {

        if(config[host]) {

            if(!_configs[host]) {
                _configs[host] = Tools.deepExtend({}, config.default, config[host]);
            }

            return Tools.getJSON(_configs[host], key, defaultValue);

        }

        return Tools.getJSON(config.default, key, defaultValue);
        
    }
}

export default Config;
