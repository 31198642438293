import Polyglot from 'node-polyglot';

import Theming from './Theming';

import Tools from './Tools';

let polyglot;

let oldTranslation;

const TAG = 'Translate';

let translate = (id, what, forcedLanguage) => {

    let language = forcedLanguage || Theming.language();

    let newTranslation = Theming.translations(language);

    if(!newTranslation) {
        newTranslation = Theming.translations(Theming.DEFAULT_LANGUAGE);
    }

    if(oldTranslation !== JSON.stringify(newTranslation)) {
        oldTranslation = newTranslation;

        Tools.debug(TAG, 'Updating translations');

        polyglot = new Polyglot();
        polyglot.extend(newTranslation);
    }

    return polyglot.t(id, what);
};

export default translate;