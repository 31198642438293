import React, { Component } from 'react';

class Load {

    static __loop(importComponents, prefix = '') {

        let result = {};

        for(let importComponent of importComponents) {
            result[importComponent] = Load.__component(prefix + importComponent);
        }

        return result;
    }

    static __component(importComponent) {

        class AsyncComponent extends Component {
            constructor(props) {
                super(props);

                this.state = {
                    component: null
                };
            }

            componentWillUnmount() {
                this.willUnmount = true;
            }

            componentDidMount() {
                import('../components/' + importComponent).then(component => {

                    if(!this.willUnmount) {
                        this.setState({ component: component.default });
                    }

                });
            }

            render() {
                const C = this.state.component;

                return C ? <C {...this.props} /> : null;
            }
        }

        return AsyncComponent;

    }

    static __load(prefix, ...components) {
        prefix += '/';

        if(components.length > 1) {
            return Load.__loop(components, prefix);
        }

        return Load.__component(prefix + components[0]);
    }

    static component(importComponent) {
        return Load.__component(importComponent);
    }

    static components(importComponents) {
        return Load.__loop(importComponents);
    }

    static uiComponent(importComponent) {
        return Load.__component('ui_components/' + importComponent);
    }

    static uiComponents(importComponents) {
        return Load.__loop(importComponents, 'ui_components/');
    }

    static questionComponent(importComponent) {
        return Load.__component('question/' + importComponent);
    }

    static questionComponents(importComponents) {
        return Load.__loop(importComponents, 'question/');
    }

    static container(...components) {
        return Load.__load('containers', ...components);
    }

    static chatbotComponent(...components) {
        return Load.__load('chatbot', ...components);
    }

}

export default Load;