import Config from './Config';

class Tools {

    static isObject(myVar) {
        return Object.prototype.toString.call(myVar) === '[object Object]';
    }

    /**
     * Make a deep extension between multiple objects
     * @param dest
     * @param sources
     * @returns Object
     */
    static deepExtend(dest, ...sources) {

        var extendObject = function(target, obj) {

            for(let key in obj) {

                let value = obj[key];

                if(target.hasOwnProperty(key) && Tools.isObject(value)) {
                    extendObject(target[key], value);
                }
                else {
                    target[key] = value;
                }
            }

        };

        if(!Tools.isObject(dest)) {
            throw Error('Dest must be an object');
        }

        for(let source of sources) {

            // if source is not a valid object, skip it
            if(!Tools.isObject(source)) {
                continue;
            }

            extendObject(dest, source);

        }

        return dest;
    }

    /*
     find value in a json via string and do not send error, return a default value
     json            : object
     key             : string
     ex: "questions.category.32.title", "questions[2].categories[32].title"
     defaultValue    : String
     */
    static getJSON(json, key, defaultValue) {
        // if the provided key is not a string, set it as a string
        if(key && typeof key !== 'string') {
            key += '';
        }
        // if no key
        if(!key) {
            return json || (defaultValue === undefined ? {} : defaultValue);
        }
        if(json) {
            let split = key.split(".");
            let value = json,
                found = false;
            for(let i = 0, length = split.length; i < length; i++) {
                let k = split[i],
                    index = null;

                // test to see if k has a index like [0] at his end
                let match = k.match(/\[(\d)\]/);
                if(match && match.index + match["0"].length === match.input.length) {
                    let i = parseInt(match[1], 10);
                    if(i >= 0) {
                        index = i;
                        k = k.substring(0, match.index);
                    }
                }
                if(value && Tools.isObject(value) && value.hasOwnProperty(k)) {
                    value = value[k];
                    if(index !== null && Array.isArray(value)) {
                        value = value[index];
                    }
                    if(i === length - 1) {
                        found = true;
                    }
                }
                else {
                    break;
                }
            }
            if(found) {
                if(typeof defaultValue === "number") {
                    return parseInt(value, 10);
                }
                return value;
            }
        }
        return defaultValue;
    }

    static findObjectInArray(arr, key, value, defaultValue = null) {
        if(!(arr instanceof Array)) {
            return defaultValue;
        }
        for(let obj of arr) {
            if(Tools.getJSON(obj, key) === value) {
                return obj;
            }
        }
        return defaultValue;
    }

    static getUrlParams() {

        let params = {};

        let search = window.location.search;

        if(search) {
            let parts = search.substr(1).split(/&|=/);
            for(let i = 0, length = parts.length; i < length; i = i + 2) {

                let part = parts[i];
                let value = decodeURIComponent(parts[i + 1]);

                if(part && value !== undefined) {
                    params[part] = value;
                }
            }
        }

        return params;
    }

    static nl2br(str) {
        return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br>$2');
    }

    static debug(context, message) {
        if(Config.get('DEBUG')) {

            if(message) {
                context += ': ';
            }
            else {
                message = context;
                context = '';
            }

            if(Array.isArray(message)) {
                for(let mess of message) {
                    console.log(context + mess);
                }
            }
            else {
                console.log(context + message);
            }
        }
    }

    /**
     * Format a number with a given number of decimals
     * @param value
     * @param nbrOfDecimals
     * @returns {string}
     */
    static formatDecimals(value, nbrOfDecimals) {
        return Number.parseFloat(value).toFixed(nbrOfDecimals);
    }

    /**
     * Convert hex color to rgb object
     * @returns object || null
     * @param hex string
     */
    static hexToRgb(hex) {
        // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
        let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;

        hex = hex.replace(shorthandRegex, (m, r, g, b) => (r + r + g + g + b + b));

        let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        if(result) {
            return {
                r: parseInt(result[1], 16),
                g: parseInt(result[2], 16),
                b: parseInt(result[3], 16)
            };
        }
        return null;
    }

    /**
     * Hash a string
     * @param str
     * @returns {number}
     */
    static hashCode(str) {
        let h = 0, l = str.length, i = 0;
        while (i < l) {
            h = (h << 5) - h + str.charCodeAt(i++) | 0;
        }
        return h;
    }

    /**
     * Set first letter of a strnig capitalized
     * @param str
     * @return {string}
     */
    static ucFirst(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

}

export default Tools;