import Tools from './Tools';

class Storage {

    static KEY_TOKEN                = 'TOKEN';
    static KEY_ACCESS_TOKEN_PAYLOAD = 'ACCESS_TOKEN_PAYLOAD';
    static KEY_REFRESH_TOKEN        = 'REFRESH_TOKEN'
    static KEY_CAMPAIGN             = 'CAMPAIGN';
    static KEY_TODAY                = 'TODAY';
    static KEY_PROFILE              = 'PROFILE';
    static KEY_PREVIEW_THEME        = 'PREVIEW_THEME';
    static KEY_ONBOARDING           = 'ONBOARDING';
    static KEY_COLLECTIVE_CHALLENGE = 'COLLECTIVE_CHALLENGE';
    static KEY_PROGRAM              = 'PROGRAM';
    static KEY_MULTI_CAMPAIGN       = 'MULTI_CAMPAIGN';
    static KEY_ACCEPT_COOKIES       = 'ACCEPT_COOKIES';
    static KEY_AGREEMENTS           = 'AGREEMENTS';
    static KEY_INSTITUTION          = 'INSTITUTION';
    static KEY_QUALIFICATION        = 'QUALIFICATION';
    static KEY_QUESTION             = 'QUESTION';
    static KEY_SERVICE_WORKER       = 'SERVICE_WORKER'; 

    static get keys() {
        return {
            [this.KEY_TOKEN]                : 'auth_token',
            [this.KEY_ACCESS_TOKEN_PAYLOAD] : {
                name: 'access_token_payload',
                keepOnLogout: false
            },
            [this.KEY_REFRESH_TOKEN]        : {
                name: 'refresh_token',
                keepOnLogout: false  // Set to true if remember me system will be implements
            },            
            [this.KEY_CAMPAIGN]             : 'campaign',
            [this.KEY_TODAY]                : {
                name: 'today',
                ttl : 120000
            },
            [this.KEY_PROFILE]              : {
                name: 'profile',
                ttl : 900000
            },
            [this.KEY_PREVIEW_THEME]        : {
                name        : 'preview_theme',
                keepOnLogout: true
            },
            [this.KEY_ONBOARDING]           : {
                name        : 'onboarding',
                keepOnLogout: true
            },
            [this.KEY_COLLECTIVE_CHALLENGE] : {
                name: 'collective_challenge',
                ttl : 300000
            },
            [this.KEY_MULTI_CAMPAIGN]       : 'multi_campaign',
            [this.KEY_ACCEPT_COOKIES]       : {
                name        : 'accept_cookies',
                keepOnLogout: true
            },
            [this.KEY_AGREEMENTS]           : {
                name        : 'agreements',
                keepOnLogout: true
            },
            [this.KEY_INSTITUTION]          : {
                name        : 'institution',
                keepOnLogout: true
            },
            [this.KEY_QUALIFICATION]        : {
                name: 'qualification',
                ttl : 300000
            },
            [this.KEY_ENDQUESTION]          : {
                name: 'endQuestion'
            },
            [this.KEY_QUESTION]             : {
                name: 'question',
                keepOnLogout: false
            },
            [this.KEY_SERVICE_WORKER]       : {
                name        : 'service_worker',
                keepOnLogout: true
            },
        };
    }

    static getKeyName(k) {
        let key = Storage.keys[k];
        if (key) {
            return 'sparky_' + (typeof key === 'string' ? key : key.name);
        }
        return null;
    }

    static getKeyTTL(k) {

        let key = Storage.keys[k];

        if (Tools.isObject(key)) {
            return key.ttl;
        }

        return null;
    }

    static getExpirationKeyName(key) {
        return Storage.getKeyName(key) + '_expiration';
    }

    static setString(key, value) {

        let keyName = Storage.getKeyName(key);

        let ttl = Storage.getKeyTTL(key);

        if (value) {

            if(ttl) {
                localStorage.setItem(Storage.getExpirationKeyName(key), Date.now());
            }

            return localStorage.setItem(keyName, value);
        }

        // if we do not have a value, remove the key
        if(ttl) {
            localStorage.removeItem(Storage.getExpirationKeyName(key));
        }

        return localStorage.removeItem(keyName);
    }

    static getStringFromStorage(key) {
        return localStorage.getItem(Storage.getKeyName(key));
    }

    static getAuthInfo() {
        return Storage.getJsonFromStorage(this.KEY_ACCESS_TOKEN_PAYLOAD);
    }

    static setJson(key, value) {
        if (value) {
            try {
                value = JSON.stringify(value);
            }
            catch (e) {
                value = null;
            }
        }
        return Storage.setString(key, value);
    }

    static getJsonFromStorage(key) {
        let value = Storage.getStringFromStorage(key);
        if (value) {
            try {
                value = JSON.parse(value);
            }
            catch (e) {
                value = null;
            }
        }
        return value;
    }

    static hasExpired(key) {

        let ttl = Storage.getKeyTTL(key);

        if(ttl) {
            let timestamp = parseInt(localStorage.getItem(Storage.getExpirationKeyName(key)));

            if(timestamp) {
                return Date.now() > timestamp + ttl;
            }
            return true;
        }
        return false;
    }

    static getToday(key, defaultValue = null) {
        let today = Storage.today;
        if (key) {
            return Tools.getJSON(today, key, defaultValue);
        }
        return today || defaultValue;
    }

    static getCampaign(key, defaultValue = null) {
        let campaign = Storage.campaign;
        if (key) {
            return Tools.getJSON(campaign, key, defaultValue);
        }
        return campaign || defaultValue;
    }

    static getProgram(key, defaultValue = null) {
        let program = Storage.campaign.program;
        if (key) {
            return Tools.getJSON(program, key, defaultValue);
        }
        return program || defaultValue;
    }

    static getInstitution(key, defaultValue = null) {
        let institution = Storage.institution;
        if (key) {
            return Tools.getJSON(institution, key, defaultValue);
        }
        return institution || defaultValue;
    }

    static getChallenge(key, defaultValue = null) {
        let challenge = Storage.challenge;
        if (key) {
            return Tools.getJSON(challenge, key, defaultValue);
        }
        return challenge || defaultValue;
    }

    static getProfile(key, defaultValue = null) {
        let profile = Storage.profile;
        if (key) {
            return Tools.getJSON(profile, key, defaultValue);
        }
        return profile || defaultValue;
    }

    static hasOnBoarded(type) {
        let hash = Tools.hashCode(Storage.getProfile('srn'));
        return Tools.getJSON(Storage.onBoarding, [hash, type].join('.'));
    }

    static onBoard(type) {
        let onBoarding = Storage.onBoarding || {};

        let hash = Tools.hashCode(Storage.getProfile('srn'));

        if(!onBoarding[hash]) {
            onBoarding[hash] = {};
        }

        onBoarding[hash][type] = true;

        Storage.onBoarding = onBoarding;
    }

    static logout() {
        for(let k in Storage.keys) {
            if(Storage.keys.hasOwnProperty(k)) {
                let key = Storage.keys[k];
                if(typeof key === 'string' || !key.keepOnLogout) {
                    Storage.setString(k);
                }
            }
        }
    }
    static isAuth(){
        return Storage.authToken!==null && Storage.authToken!==undefined && Storage.authToken.length>0;
    }
    static set authToken(token) {
        Storage.setString(this.KEY_TOKEN, token);
        Storage.setJson(this.KEY_ACCESS_TOKEN_PAYLOAD, JSON.parse(decodeURIComponent(atob(token.split('.')[1]))))
    }

    static get authToken() {
        return Storage.getStringFromStorage(this.KEY_TOKEN);
    }

    static get tokenExpiresOn() {
        const payload =  Storage.getJsonFromStorage(this.KEY_ACCESS_TOKEN_PAYLOAD)
        if(payload === null){
            return 0
        }

        return payload.exp || 0
    }


    static set refreshToken(token) {
        Storage.setString(this.KEY_REFRESH_TOKEN, token);
    }

    // Return a string not empty or null
    static get refreshToken() {
        return Storage.getStringFromStorage(this.KEY_REFRESH_TOKEN) || null;
    }

    static get campaign() {
        return Storage.getJsonFromStorage(this.KEY_CAMPAIGN);
    }

    static set campaign(campaign) {
        return Storage.setJson(this.KEY_CAMPAIGN, campaign);
    }

    static get program() {
        return this.getCampaign().program;
    }

    static get today() {
        return Storage.getJsonFromStorage(this.KEY_TODAY);
    }

    static set today(today) {
        return Storage.setJson(this.KEY_TODAY, today);
    }

    static get profile() {
        return Storage.getJsonFromStorage(this.KEY_PROFILE);
    }

    static set profile(profile) {
        return Storage.setJson(this.KEY_PROFILE, profile);
    }

    static set institution(institution) {
        return Storage.setJson(this.KEY_INSTITUTION, institution);
    }

    static get institution() {
        return Storage.getJsonFromStorage(this.KEY_INSTITUTION);
    }

    static set previewTheme(previewTheme) {
        return Storage.setJson(this.KEY_PREVIEW_THEME, previewTheme);
    }

    static get previewTheme() {
        return Storage.getJsonFromStorage(this.KEY_PREVIEW_THEME);
    }

    static get onBoarding() {
        return Storage.getJsonFromStorage(this.KEY_ONBOARDING);
    }

    static set onBoarding(onBoarding) {
        return Storage.setJson(this.KEY_ONBOARDING, onBoarding);
    }

    static get collectiveChallenge() {
        return Storage.getJsonFromStorage(this.KEY_COLLECTIVE_CHALLENGE);
    }

    static set collectiveChallenge(collectiveChallenge) {
        return Storage.setJson(this.KEY_COLLECTIVE_CHALLENGE, collectiveChallenge);
    }

    static get multiCampaign() {
        return Storage.getStringFromStorage(this.KEY_MULTI_CAMPAIGN);
    }

    static set multiCampaign(multiCampaign) {
        return Storage.setString(this.KEY_MULTI_CAMPAIGN, multiCampaign);
    }

    static get acceptCookies() {
        return Storage.getStringFromStorage(this.KEY_ACCEPT_COOKIES);
    }

    static set acceptCookies(acceptCookies) {
        return Storage.setString(this.KEY_ACCEPT_COOKIES, acceptCookies);
    }

    static get agreements() {
        return Storage.getJsonFromStorage(this.KEY_AGREEMENTS);
    }

    static set agreements(agreements) {
        return Storage.setJson(this.KEY_AGREEMENTS, agreements);
    }

    static get qualification() {
        return Storage.getJsonFromStorage(this.KEY_QUALIFICATION);
    }

    static set qualification(today) {
        return Storage.setJson(this.KEY_QUALIFICATION, today);
    }

    static get questionEnd(){
        return Storage.getJsonFromStorage(this.KEY_ENDQUESTION)
    }

    static set questionEnd(today){
        return Storage.setJson(this.KEY_ENDQUESTION, today);
    }

    static get question() {
        return Storage.getJsonFromStorage(this.KEY_QUESTION);
    }

    static set question(data) {
        return Storage.setJson(this.KEY_QUESTION, data);
    }

    static get serviceWorker() {
        return Storage.getStringFromStorage(this.KEY_SERVICE_WORKER);
    }

    static set serviceWorker(serviceWorker) {
        return Storage.setString(this.KEY_SERVICE_WORKER, serviceWorker);
    }

}

export default Storage;
