import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';

import ReactDOM from 'react-dom';

import WebFont from 'webfontloader';

import './css/index.scss';

import 'animate.css';

import { Tools, Ajax, Storage, Load } from './utils';

import CircularProgress from '@material-ui/core/CircularProgress';

const App = Load.component('App');

WebFont.load({
    google: {
        families: ['Open Sans:300,400,600,700,800', 'Metrophobic:regular', 'Material Icons', 'sans-serif']
    }
});

ReactDOM.render(<CircularProgress style={{
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginLeft: '-20px',
    marginTop: '-20px'
}} />, document.getElementById('root'));

let start = () => ReactDOM.render(<App />, document.getElementById('root'));

Storage.previewTheme = null;

Storage.institution = null;

// get theme for a given themeId OR for the current domain
Ajax.get('front/institution', (err, body) => {

    if(err) {
        console.log(err);
    }

    Storage.institution = Tools.getJSON(body, 'body');

    const params = Tools.getUrlParams();

    let id = params.theme;

    let token = params.token;

    if(!id || !token) {
        return start();
    }

    Ajax.get('front/preview/theme', { id, token }, (err, body) => {
        Storage.previewTheme = body.body;

        return start();
    });

});

