import moment from 'moment-timezone';

import Theming from './Theming';

import Storage from './Storage';

let lang = null;

let timezone = null;

class Moment {

    static get instance() {

        let newLang = Theming.language();

        if(lang !== newLang) {
            lang = newLang;
            if(lang !== Theming.DEFAULT_LANGUAGE) {
                let momentLang;

                switch(lang) {
                    case 'zh':
                        momentLang = 'zh-cn';
                        break;
                    case 'zw':
                        momentLang = 'zh-tw';
                        break;
                    default:
                        momentLang = lang;
                }

                try {
                    require('moment/locale/' + momentLang);
                }
                catch(e) {
                    console.log('Unable to load moment locale', momentLang);
                    console.log(e);
                    lang = Theming.DEFAULT_LANGUAGE;
                }
            }
            moment.locale(lang);
        }

        let newTimezone = Storage.getCampaign('timezone');

        if(newTimezone !== timezone) {
            timezone = newTimezone;
            moment.tz.setDefault(timezone);
        }

        return moment;
    }
}

export default Moment;